







































import { Component, Prop, Vue, Provide, Watch } from 'vue-property-decorator';
import { accountLogin, accountRegisterResend } from '@/apis/account.api';
import { Action, State } from 'vuex-class';
import {
  mounted,
  loadings,
  errorPopupHandler,
  errorCatchHandler,
} from '@/utils/decorators/VueTimmer';

@Component
export default class NotFoundPage extends Vue {
  @State(state => state.account.detail)
  accountDetail: any;

  @Provide()
  loginng = false;

  @Provide()
  form = {
    email: '',
    password: '',
  };

  @Provide()
  errors = {
    login: '',
  };

  @loadings('loginng')
  @Action('account/login')
  async login({ email, password }): Promise<any> {}

  @errorCatchHandler('errors.login', 'Some thing went wrong', 'form')
  onSubmit() {
    return this.login({
      ...this.form,
    }).then(({ data, error }) => {
      if (data) this.$router.replace({ name: 'account_detail' });
      else if (error) {
        if (error == 'activate') {
          accountRegisterResend({
            email: this.form.email,
          });

          this.$router.replace({
            name: 'register_verify',
            query: {
              email: this.form.email,
            },
          });
        } else if (error === '2fa') {
          this.$router.replace({
            name: '2fa'
          });
        }
      }
    });
  }

  onReset() {}
  mounted() {
    if (this.accountDetail && this.accountDetail.email) {
      this.$router.push({ name: 'account_detail' });
    }
  }

  @Watch('accountDetail')
  onChangeAccountDetail(newVal) {
    if (newVal && newVal.email) {
      this.$router.push({ name: 'account_detail' });
    }
  }
}
